import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout, Section, BlogPostsGrid, Link, BlogHeader } from '../components';
import * as styles from './news.module.scss';

const Blog = () => {
  const blogData = useStaticQuery(graphql`
    query blogQuery {
      ...BlogQuery
    }
  `);

  console.log('news page runs')

  const {
    categories: { nodes: categoryNodes },
    allPosts: { nodes: posts },
    lpData: { blogDescription, blogFeatureImage, blogMetaDescription, blogMetaTitle, blogTitle },
  } = blogData;

  const seo = {
    title: blogMetaTitle || '',
    desc: blogMetaDescription || '',
  };

  return (
    <Layout seo={seo}>
      <BlogHeader title={blogTitle} subtitle={blogDescription} image={blogFeatureImage} />
      <Section containerClassName={styles.blogContainer}>
        <BlogSideBar categories={categoryNodes} />
        <BlogPostsGrid posts={posts} />
      </Section>
    </Layout>
  );
};

export default Blog;

const BlogSideBar = ({ categories }) => (
  <div className={styles.sidebarContainer}>
    <div className={styles.sidebar}>
      <span className={styles.categoryTitle}>Categories</span>
      <div className={styles.categoriesContainer}>
        <Link to="/news" className={styles.category}>
          All
        </Link>
        {categories.map((item) => {
          const { title, slug } = item;
          return (
            <Link to={slug.current} className={styles.category}>
              {title}
            </Link>
          );
        })}
      </div>
    </div>
  </div>
);
